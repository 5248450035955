import React, { useState, useRef } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../redux/AuthCRUD'
import '../../../../_metronic/assets/sass/stylejoe.scss'

interface OTPFormValues {
  email: string
  otp: string[]
}

const initialValues: OTPFormValues = {
  email: 'admin@demo.com',
  otp: ['', '', '', '', '', ''], // Define OTP as an array of strings
}

const OTPSchema = Yup.object().shape({
  otp: Yup.array()
    .of(
      Yup.string()
        .min(1, 'Minimum 1 symbol')
        .max(1, 'Maximum 1 symbol')
        .required('This field is required')
    )
    .length(6, 'OTP must be exactly 6 digits'),
})

export function OTP() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  
  const formik = useFormik<OTPFormValues>({
    initialValues,
    validationSchema: OTPSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  // Refs to focus the next input
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = e.target

    if (value.length > 1) return // Prevent input longer than 1 character

    // Update formik values
    formik.setFieldValue(`otp[${index}]`, value)

    // Move to the next input if there's a value
    if (value) {
      const nextIndex = index + 1
      if (nextIndex < formik.values.otp.length) {
        inputRefs.current[nextIndex]?.focus()
      }
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e)
  }

  return (
    <div className='OTPpage'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Verify your phone</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Enter the verification code we sent to ****
          </div>
        </div>

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}

        <div className="fv-row mb-10">
          <div className="flex flex-wrap justify-center gap-2.5">
            {formik.values.otp.map((_, index) => (
              <input
                key={index}
                name={`otp[${index}]`}
                className="input focus:border-primary-clarity focus:ring focus:ring-primary-clarity size-10 shrink-0 px-0 text-center"
                type="text"
                maxLength={1} // Enforce single character input
                value={formik.values.otp[index]}
                onChange={(e) => handleChange(e, index)}
                onBlur={handleBlur}
                ref={el => inputRefs.current[index] = el}
              />
            ))}
          </div>
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading || !formik.isValid || formik.isSubmitting}
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  )
}
